<template>
  <div id="ppt">
    <div class="box">
      <div class="main">
        <iframe
          id="iframe1"
          width="1362"
          height="838"
          frameborder="no"
          border="0"
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          allowtransparency="yes"
          :src="artical"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return { artical: "", imgurl: "", names: "" };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
        this.artical =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          "http://zy.yunlearning.com" +
          res.data.file_path;
        this.imgurl = res.data.cover;
        this.names = res.data.file_name;
        console.log(res);
      });
    },
  },
};
</script>
<style lang="less" scoped>
#ppt {
  height: 100%;
  .box {
    height: calc(100vh - 0.625in);
    // min-height: 1020px;
    position: relative;
  }
  .main {
    width: 91%;
    // height: 800px;
    // background-color: #62e6db;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
}
</style>